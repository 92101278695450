var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form",
    { attrs: { form: _vm.form } },
    [
      _c(
        "a-form-item",
        _vm._b(
          {
            staticStyle: { margin: "12px 0" },
            attrs: { label: _vm.$t("Name") }
          },
          "a-form-item",
          _vm.formItemLayout,
          false
        ),
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["name", _vm.formItemOptions.name],
                expression: "['name', formItemOptions.name]"
              }
            ],
            attrs: { placeholder: _vm.$t("Please input your project name") }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        _vm._b(
          {
            staticStyle: { margin: "12px 0" },
            attrs: { label: _vm.$t("Descriptions") }
          },
          "a-form-item",
          _vm.formItemLayout,
          false
        ),
        [
          _c("a-textarea", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["description", _vm.formItemOptions.description],
                expression: "['description', formItemOptions.description]"
              }
            ],
            attrs: {
              rows: 3,
              placeholder: _vm.$t("Please input your project description")
            }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        _vm._b(
          {
            staticClass: "mb-none",
            staticStyle: { margin: "12px 0" },
            attrs: { label: _vm.$t("Photo") }
          },
          "a-form-item",
          _vm.formItemLayout,
          false
        ),
        [
          _c("UploadPhoto", {
            attrs: {
              url: _vm.thumbnail.url,
              "folder-name-s3": _vm.folderNameS3
            },
            on: {
              upload: function(newURL) {
                _vm.thumbnail = Object.assign({}, _vm.thumbnail, {
                  url: newURL
                })
              },
              delete: function() {
                _vm.thumbnail = { url: "", alt: "" }
              }
            }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        _vm._b(
          {
            staticStyle: { margin: "12px 0" },
            attrs: { label: _vm.$t("Link") }
          },
          "a-form-item",
          _vm.formItemLayout,
          false
        ),
        [
          _c(
            "a-tooltip",
            { attrs: { placement: "top", title: _vm.$t("Click to copy") } },
            [
              _c(
                "a-button",
                {
                  staticClass: "copy-clipboard",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.handleCopyLink(_vm.pathToProject)
                    }
                  }
                },
                [
                  _c("span", { staticClass: "mr-sm" }, [
                    _vm._v(_vm._s(_vm.pathToProject))
                  ]),
                  _c("a-icon", { attrs: { type: "copy" } })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-form-item",
        _vm._b(
          {
            staticStyle: { margin: "12px 0" },
            attrs: { label: _vm.$t("Sub Domain") }
          },
          "a-form-item",
          _vm.formItemLayout,
          false
        ),
        [
          _c(
            "a-row",
            { attrs: { type: "flex", justify: "start" } },
            [
              _c(
                "a-col",
                { attrs: { lg: 20, xl: 12 } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["sub_domain", _vm.formItemOptions.sub_domain],
                        expression: "['sub_domain', formItemOptions.sub_domain]"
                      }
                    ],
                    attrs: {
                      "addon-before": "https://",
                      addonAfter: _vm.baseSubDomain,
                      placeholder: _vm.slug
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                [
                  _c("a-button", {
                    staticClass: "mx-xs",
                    attrs: { icon: "copy" },
                    on: {
                      click: function($event) {
                        return _vm.handleCopyLink(_vm.pathToHomePage)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-form-item",
        _vm._b(
          {
            staticStyle: { margin: "12px 0" },
            attrs: { label: _vm.$t("Security") }
          },
          "a-form-item",
          _vm.formItemLayout,
          false
        ),
        [
          _c("a-switch", {
            attrs: {
              "checked-children": _vm.$t("Public"),
              "un-checked-children": _vm.$t("Private")
            },
            model: {
              value: _vm.project.is_public,
              callback: function($$v) {
                _vm.$set(_vm.project, "is_public", $$v)
              },
              expression: "project.is_public"
            }
          }),
          _c(
            "router-link",
            {
              staticClass: "ml-sm",
              attrs: { to: { query: { tab: "schedule" } }, replace: "" }
            },
            [_vm._v(" " + _vm._s(_vm.$t("Schedule setting")) + " ")]
          ),
          _c("br"),
          _c(
            "span",
            { staticClass: "pl-sm mb-sm", staticStyle: { color: "#eca30c" } },
            [
              _c("a-icon", { attrs: { type: "info-circle" } }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "This action will change the visibility of all pages in this project."
                    )
                  ) +
                  " "
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-form-item",
        _vm._b(
          {
            staticStyle: { margin: "12px 0" },
            attrs: { label: _vm.$t("Page list") }
          },
          "a-form-item",
          _vm.formItemLayout,
          false
        ),
        [
          _c("router-link", { attrs: { to: _vm.pathToPages } }, [
            _vm._v(_vm._s(_vm.$t("View page list")))
          ])
        ],
        1
      ),
      _c(
        "a-form-item",
        _vm._b(
          {
            staticStyle: { margin: "12px 0" },
            attrs: { label: _vm.$t("Dictionaries") }
          },
          "a-form-item",
          _vm.formItemLayout,
          false
        ),
        [
          _c(
            "a-row",
            [
              _c("a-col", [
                _c(
                  "p",
                  {
                    staticStyle: {
                      width: "100%",
                      "line-height": "normal",
                      color: "rgb(122 122 122)",
                      "margin-bottom": "4px"
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "Choosing dictionary will enable customize the pronunciation of words when reading content. Maximum 5 dictionaries can be selected."
                          )
                        ) +
                        " "
                    )
                  ]
                )
              ])
            ],
            1
          ),
          _c("a-tree-select", {
            staticStyle: { width: "100%" },
            attrs: {
              "tree-data": _vm.dictionaryTreeData,
              "tree-checkable": "",
              "search-placeholder": _vm.$t("Please select")
            },
            model: {
              value: _vm.dictionary,
              callback: function($$v) {
                _vm.dictionary = $$v
              },
              expression: "dictionary"
            }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        _vm._b(
          {
            staticStyle: { margin: "0" },
            attrs: { label: _vm.$t("Created date") }
          },
          "a-form-item",
          _vm.formItemLayout,
          false
        ),
        [_c("span", [_vm._v(_vm._s(_vm._f("dayjs")(_vm.project.created)))])]
      ),
      _c(
        "a-form-item",
        _vm._b(
          {
            staticStyle: { margin: "0" },
            attrs: { label: _vm.$t("Updated date") }
          },
          "a-form-item",
          _vm.formItemLayout,
          false
        ),
        [_c("span", [_vm._v(_vm._s(_vm._f("dayjs")(_vm.project.modified)))])]
      ),
      _c(
        "a-form-item",
        { attrs: { wrapperCol: { offset: 3, span: 4 }, colon: false } },
        [
          _c(
            "a-button",
            {
              attrs: { loading: _vm.loading, type: "primary" },
              on: { click: _vm.handleSubmitForm }
            },
            [_vm._v(" " + _vm._s(_vm.$t("Change Project")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }